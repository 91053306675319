import React from 'react'

const SocialWorkerHeader = () => {
  return (
    <main style={{height: "2000px"}}>
      <div className='sw-section'></div>
        <div className="hero">Kofi</div>        
    </main>
  )
}

export default SocialWorkerHeader